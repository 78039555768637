<template>
  <b-modal id="layout-modal" 
    :title="form?.id ? 'Editar layout' : 'Novo layout'"
    hide-footer centered size="xl"
    @hidden="onHidden"
  >
    <div class="body">
      <div class="body-content">
          <Expenses
            :form="form"
            :clinicHealthPlan="clinicHealthPlan"
          />
      </div>
      <b-row class="w-100 justify-content-end mt-3 pr-2 pb-5">
        <b-button 
          variant="outline-danger"
          @click="$bvModal.hide('layout-modal')"
          class="mr-2"
        >
          Cancelar
        </b-button>
        <b-button 
          variant="primary"
          v-show="isValidForm()"
          @click="saveLayout"
        >
          Salvar layout
        </b-button>
      </b-row>
    </div>   
  </b-modal>
</template>

<script>
export default {
  name: 'LayoutModal',
  components: {
    Expenses: () => import('@/layouts/InvoicingTiss/Layouts/Expenses.vue')
  },
  props: {
    layout: Object,
    clinicHealthPlan: Object,
  },
  data() {
    return {
      form: this.getDefaultForm(),
      currentTabIndex: 0,
    }
  },
  methods: {
    isValidForm() {
      return this.form.name?.length && this.form?.expenses?.length
    },
    getDefaultForm() {
      return {
        id: null,
        name: '',
        description: '',
        clinic_health_plan_id: this.clinicHealthPlan.id,
        type: '',
        expenses: [],
      }
    },
    onHidden() {
      this.currentTabIndex = 0
      this.form = this.getDefaultForm()
    },
    async saveLayout() {
      const isLoading = this.$loading.show()
      try {
        if (!this.isValidForm()) 
          return this.$toast.error('Preencha todos os campos obrigatórios para salvar o layout')

        this.form.clinic_health_plan_id = this.clinicHealthPlan.id
        let res = null
        this.form?.id
          ? res = await this.api.updateLayout(this.form?.id, this.form)
          : res = await this.api.createLayout(this.form)

        this.$set(this.form, 'id', res?.data?.id)
        this.$toast.success(`Layout ${this.form?.id ? 'atualizado' : 'criado'} com sucesso`)
        this.$emit('save', this.form)
        this.$bvModal.hide('layout-modal')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
  },
  watch: {
    layout: {
      handler() {
        this.form = this.layout ?? this.getDefaultForm()
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style lang="scss">
#layout-modal {
  .modal-content {
    .modal-body {
      padding: 1rem;
    }
  }

  button {
    border-radius: 8px;
  }
}
</style>
